<template>
  <div class="float-right mt-3">
    <i class="bx bx-cart"></i>
    <router-link v-if="item.merchant_id" :to="{name: `merchants-show`, params: {id: item.merchant_id}}">
      {{item.merchant_name}}
    </router-link>
  </div>
</template>

<script>
// import Value from "@/components/Value";
import Resource from '@/services/Resource';

export default {
  // components: { Value },
  props: {
    item: {
      type: Resource,
      required: true,
    },
  },
};
</script>