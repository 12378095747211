<template>
  <b-card no-body>
    <template #header> 
      <i class='bx' :class="icon" />
      {{$t(title)}}
    </template>
    <b-table 
      small 
      striped 
      hover
      show-empty
      ref="table"
      :empty-text="$t('locations.no-packages')"
      :items="packages" 
      :tbodyTrClass="{linkish: packages.length}"
      :fields="fields | transMap"
      @row-clicked="p => p.$open()"
    >
      <template #cell(status)="{item}">
        <status v-bind="{item}" />
      </template>
      <template #empty="scope">
        <div class="text-center m-2">{{ scope.emptyText }}</div>
      </template>
    </b-table>
  </b-card>
</template>

<script>
import resource from '@/resources/Package'
import Status from '@/components/Status.vue'

export default {
  components: {Status},
  data() {
    return {
    };
  },
  props: {
    packages: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      default: 'packages.title',
    },
    icon: {
      type: String,
      default: 'bxs-package',
    }
  },
  computed: {
    fields() {
      return [
        ...resource.fields,
      ].filter(Boolean)
    }
  },
  methods: {
  }
}
</script>