<template>
  <MainLayout>
    <b-overlay
      :show="loading"
      rounded
      spinner-type="grow"
      spinner-variant="primary"
      style="min-height: 90vh"
    >
      <div v-if="item">
        <merchant-card v-if="(userIsSuperAdmin || userIsCustomerSupport) && item.isPickup" v-bind="{item}" />
        <h1>
          <i :class="['bx', resource.icon]"></i>
          {{ item.$meta.title }}
          <span v-if="!item.active" class="text-danger small mr-3">{{$t('common.inactive')}}</span>
          <span v-if="item.isPickup && !item.approved" class="text-danger small mr-3">{{$t('locations.not-approved')}}</span>
        </h1>

        <action-bar v-bind="{item,resource}" @action="action" />

        <div class="row row-cols-1 row-cols-md-2 mt-4" :class="{'inactive': !item.active}">
          <div class="col mb-4">
            <b-card>
              <template #header>
                <div class="float-right">
                  <i class='bx bx-purchase-tag-alt' title="ID"></i>
                  {{item.id}}
                </div>
                <i class="bx bxs-map"></i>
                {{ $t("locations.label") }}
              </template>
              <dl>
                <div class="row row-cols-1 row-cols-xl-2">
                  <div class="col">
                    <value label="common.name" icon="bx-purchase-tag" :value="item.name" />
                  </div>
                  <template v-if="userIsSuperAdmin">
                    <div class="col">
                      <value label="locations.type" icon="bx-sort" :value="$t(`locations.types.${item.type}`)" />
                    </div>
                    <div v-if="item.isDropoff" class="col">
                      <value label="locations.class" icon="bx-building" :value="item.class ? $t(`locations.classes.${item.class}`) : null" />
                    </div>
                    <div v-if="item.isLocker" class="col">
                      <value 
                        label="locations.locker_service" 
                        icon="bx-bolt-circle" 
                        :value="item.locker_service ? $t(`locations.locker_services.${item.locker_service}`) : null" 
                      />
                    </div>
                  </template>
                  <div class="col">
                    <value v-if="item.country" label="common.country" icon="bx-globe" :value="item.country">
                      <country :country="item.country" />
                    </value>
                  </div>
                  <template v-if="item.isDropoff">
                    <div class="col">
                      <value v-if="item.timezone" label="locations.timezone" icon="bx-time" :value="item.timezone" />
                    </div>
                    <div class="col">
                      <value label="locations.code" icon="bxs-purchase-tag" :value="item.code"/>
                    </div>
                    <div class="col">
                      <value label="locations.zone" icon="bx-map-pin" :value="item.zone"/>
                    </div>
                  </template>
                </div>

              </dl>
            </b-card>

            <ImageManager 
              v-if="userIsSuperAdmin && item.isDropoff" 
              :current="item.image"
              :upload-endpoint="`locations/${item.id}/image`"
              @change="item.$refresh()"
            />

            <b-card no-body>
              <template #header>
                <span class="float-right text-muted coordinates small">
                  ///{{words}}<br>
                  {{item.latitude}}, {{item.longitude}}
                </span>
                <i class="bx bxs-map-alt"></i>
                {{ $t("locations.coordinates") }}
              </template>
              <GmapMap
                :center="{lat:item.latitude, lng:item.longitude}"
                :zoom="12"
                map-type-id="terrain"
                style="width: 100%; height: 300px"
              >
                <GmapMarker
                  :position="{lat:item.latitude, lng:item.longitude}"
                  :clickable="true"
                />
              </GmapMap>
            </b-card>
          </div>
          <div class="col mb-4">

            <address-card :address="item.address" />

            <b-card v-if="item.isDropoff">
              <template #header>
                <i class="bx bxs-time"></i>
                {{ $t("locations.hours") }}
              </template>
              <dl>
                <value label="locations.hours" icon="bx-time-five" :value="item.hours">
                  <span class="pre">{{item.hours}}</span>
                </value>
              </dl>
            </b-card>

            <packages-card v-if="userIsSuperAdmin && (item.isStore || item.isLocker)" :packages="item.$packages" />

            <b-card v-if="userIsSuperAdmin && item.isDropoff && item.terminal_created">
              <template #header>
                <i class="bx bx-grid"></i>
                {{ $t("locations.terminal") }}
              </template>
              <dl>
                <value label="locations.secure_access_key" icon="bx-key" :value="item.secure_access_key" />
              </dl>
            </b-card>

            <activity-card :item="item" />
          </div>
        </div>
      </div>
      <debug v-bind="{item}" />
    </b-overlay>
  </MainLayout>
</template>

<script>
import MainLayout from "@/layouts/Main";
import ItemShow from "@/components/ItemShow";
import Value from "@/components/Value";
import ImageManager from "@/components/ImageManager";
import AddressCard from "@/components/Cards/Address";
import ActivityCard from "@/components/Cards/Activity";
import ActionBar from '@/components/ActionBar.vue'
import MerchantCard from '@/components/Cards/Merchant'
import { coordinatesToWords } from '@/services/What3words'
import PackagesCard from '@/components/Cards/Packages'
import Country from "@/components/Country";

export default {
  extends: ItemShow,
  data() {
    return {
      words: null
    }
  },
  components: {
    MainLayout,
    AddressCard,
    ActivityCard,
    Value,
    ActionBar,
    MerchantCard,
    PackagesCard,
    ImageManager,
    Country,
  },
  computed: {
  },
  methods: {
    async fetched() {
      this.words = await this.getWords()
    },
    async getWords() {
      let {data: {words}} = await coordinatesToWords({
        lat: this.item.latitude,
        lng: this.item.longitude,
      })

      return words
    }
  }
};
</script>

<style lang="scss" scoped>
  .coordinates {
    text-transform: none;
    margin-top: -.4rem;
    margin-bottom: -.5rem;
  }
</style>