<template>
  <b-card>
    <template #header>
      <i class="bx bxs-building-house"></i>
      {{ $t("address.label") }}
    </template>
    <div>
      <i
        class="bx bxs-info-circle bx-sm float-right text-info"
        v-b-tooltip.hover
        :title="$t('address.info')"
      ></i>
      <div v-if="address.company" v-text="address.company" />
      <div v-if="address.other" v-text="address.other" />

      <div class="show-address" v-text="formattedAddress" />

      <div v-if="address.description" v-text="address.description" />

      <div v-if="address.country">
        <country :country="address.country" />
      </div>
    </div>
  </b-card>
</template>

<script>
import Country from "../Country";
import addressFormatter from "@fragaria/address-formatter/dist/es/address-formatter";

export default {
  components: { Country },
  props: {
    address: {
      type: Object,
      required: true,
    },
  },
  computed: {
    formattedAddress() {
      return addressFormatter.format({
        houseNumber: this.address.house_number,
        road: this.address.street,
        // "neighbourhood": "Crescent Park",
        city: this.address.locality,
        postcode: this.address.postcode,
        // "county": "Santa Clara County",
        state: this.address.state,
        // "country": "United States of America",
        countryCode: this.address.country,
      });
    },
  },
};
</script>