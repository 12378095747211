const axios = require("axios");

const w3w = axios.create({
  baseURL: 'https://api.what3words.com/v3/',
  params: {
    key: process.env.VUE_APP_WHAT3WORDS_API_KEY
  }
});

export const coordinatesToWords = (coordinates) => {
  let {lat,lng} = coordinates
  return w3w.get('convert-to-3wa',{
    params: {coordinates: `${lat},${lng}`}
  })
}

export const wordsToCoordinates = (words) => {
  return w3w.get('convert-to-coordinates',{
    params: {words}
  })
}