<template>
  <b-card
    :img-src="image"
    img-top
    no-body
  >
  <div class="d-none">
    <b-form-file 
      ref="input" 
      v-model="file" 
      @input="handleUpload" 
      accept="image/jpeg, image/png"
    />
  </div>
  <div class="button-container" :class="{current}">
    <b-button size="sm" :variant="current ? '' : 'primary'" @click="triggerUpload">
      <i class='bx bx-upload' ></i>
      {{current ? $t('locations.image.replace') : $t('locations.image.upload')}}
    </b-button>
    <b-button v-if="current" size="sm" variant="danger" class="ml-2" @click="deleteImage">
      <i class='bx bx-trash' ></i>
    </b-button>
  </div>
  </b-card>
</template>

<script>
import api from '@/api'
export default {
  props: {
    current: {
      type: String,
      default: null
    },
    uploadEndpoint: {
      type: String,
      required: true
    }
  },
  watch: {
    current: {
      handler(filename) {
        const placeholder = `https://placeholder.pics/svg/600x150/f7f7f7/555555-f7f7f7/${this.$t('locations.image.placeholder')}`
        this.image = filename ? `${process.env.VUE_APP_API_HOST}storage/${filename}` : placeholder
      },
      immediate: true
    }
  },
  data() {
    return {
      image: null,
      file: null
    }
  },
  methods: {
    triggerUpload() {
      this.$refs.input.$refs.input.click()
    },
    async handleUpload() {
      if(!this.file) return
      let formData = new FormData()
      formData.append('file', this.file)
      await api.post(this.uploadEndpoint, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
      })

      this.file = null

      this.$emit('change')
    },
    async deleteImage() {
      await api.delete(this.uploadEndpoint)
      this.$emit('change')
    }
  }
}
</script>

<style lang="scss" scoped>
  .button-container {
    position: absolute;
    bottom: 0;
    right: 0;
    margin: 1em;

    &.current button {
      box-shadow: 0 0 0.5rem -0.2rem #0009;
    }
  }
</style>